.cards {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-left: 15px;
    justify-content: center;
    padding: 20px;
  }
  
  .cards .card {
    display: flex;
    gap: 10px;
    /* align-items: center;
    justify-content: center; */
    flex-direction: column;
    height: auto;
    width: 350px;
    border-radius: 10px;
    cursor: pointer;
    transition: 400ms;
  }
  
  .cards .card:hover {
    transform: scale(1.1, 1.1);
  }
  
  .cards:hover > .card:not(:hover) {
    filter: blur(10px);
    transform: scale(0.9, 0.9);
  }