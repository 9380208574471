@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    overflow-x: hidden;
}

.module {
  background: 
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    ),
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #e2e8f0; /* slate-200 color */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e1; /* slightly darker than slate-200 for thumb */
  border-radius: 10px;
  transition: all ease-in-out 3s;
}

::-webkit-scrollbar-thumb:hover {
  background: #a1a1aa; /* even darker for hover effect */
  transition: all ease-in-out 3s;
}
