.card {
    width: 300px;
    height: 300px;
    transition: all 0.2s;
    position: relative;
    cursor: pointer;
    overflow: visible;
    background-color: white;
    border-radius: 8px;
  }
  
  .card-inner {
    width: inherit;
    height: inherit;
    background: rgba(255,255,255,.05);
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    position: relative;
  }
  
  .card:hover {
    transform: scale(1.04) rotate(1deg);
  }
  
  .circle {
    width: 100px;
    height: 100px;
    background-color: #EED3BA;
    border-radius: 50%;
    position: absolute;
    animation: move-up6 2s ease-in infinite alternate-reverse;
  }
  
  .circle:nth-child(1) {
    top: -25px;
    left: -25px;
  }
  
  .circle:nth-child(2) {
    bottom: -25px;
    right: -25px;
    animation-name: move-down1;
  }
  
  @keyframes move-up6 {
    to {
      transform: translateY(-10px);
    }
  }
  
  @keyframes move-down1 {
    to {
      transform: translateY(10px);
    }
  }