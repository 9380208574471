.card {
    height: auto;
    position: relative;
    padding: 25px;
    background: radial-gradient(178.94% 106.41% at 26.42% 106.41%, #8D493A 0%, rgba(255, 255, 255, 0) 71.88%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #D0B8A8;
    /* box-shadow: 0px 155px 62px rgba(0, 0, 0, 0.01), 0px 87px 52px rgba(0, 0, 0, 0.05), 0px 39px 39px rgba(0, 0, 0, 0.09), 0px 10px 21px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1); */
    border-radius: 23px;
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.08) ;
    transition: all 0.8s cubic-bezier(0.15, 0.83, 0.66, 1);
  }
  
  .card:hover {
    transform: scale(1.05);
  }